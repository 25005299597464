import React from "react";

function SummaryBlock({ mainText, subText, caption, actionButton }) {
  return (
    <div className="summary-block">
      {mainText && <div className="summary-block__main-text">{mainText}</div>}
      <div>
        {subText && <div className="summary-block__sub-text">{subText}</div>}
        {caption && <div className="summary-block__caption">{caption}</div>}
      </div>
      {actionButton && (
        <div className="summary-block__action-button">{actionButton}</div>
      )}
    </div>
  );
}

export default SummaryBlock;
